var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_vm._v("Callcenter")]),_c('div',{staticClass:"card-body",staticStyle:{"background-color":"#f9f9f9"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[(_vm.oneDegreeColumnLoading)?_c('semipolar-spinner',{attrs:{"animation-duration":2000,"size":100,"color":"blue"}},[_vm._v("Spinner")]):_vm._e(),(!_vm.oneDegreeColumnLoading && _vm.oneDegreeColumn.length)?_c('div',{staticClass:"degress-col"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-10"},[_c('h5',[_vm._v(_vm._s(_vm.gradoNames[0]))]),_c('callcenter-leads',{ref:"manageAdminLead",attrs:{"show":_vm.leadModalShow,"label":_vm.leadModalLabel,"grado":_vm.gradoLabel,"leads":_vm.leadModalPayload},on:{"update:show":function($event){_vm.leadModalShow=$event},"update:label":function($event){_vm.leadModalLabel=$event},"update:grado":function($event){_vm.gradoLabel=$event},"loadleads":_vm.loadCallCenterLeads}})],1),_c('div',{staticClass:"col-lg-2"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showAddLeadForm(_vm.gradoNames[0])}}},[_c('CIcon',{attrs:{"content":_vm.$options.plus}})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 degress-item",style:({
              'max-height':((_vm.windowHeight-300) + "px"),
              'height': (_vm.windowHeight + "px"),
              'overflow-y':'auto'
            })},[_c('table',{staticClass:"table"},[_c('tbody',_vm._l((_vm.oneDegreeColumn),function(item){return _c('tr',{key:item.id},[_c('td',[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showEditLeadForm(_vm.gradoNames[0], item)}}},[_c('p',{staticClass:"head mb-1"},[_vm._v(_vm._s(item.user_name))]),_c('p',{staticClass:"h6"},[_vm._v("Telefono : "+_vm._s(item.phone))]),_c('p',{staticClass:"h6"},[_vm._v("Email : "+_vm._s(item.email))])])])])}),0)])])])]):_vm._e()],1),_c('div',{staticClass:"col-lg-4"},[(_vm.twoDegreeColumnLoading)?_c('semipolar-spinner',{attrs:{"animation-duration":2000,"size":100,"color":"blue"}},[_vm._v("Spinner")]):_vm._e(),(!_vm.twoDegreeColumnLoading && _vm.twoDegreeColumn.length)?_c('div',{staticClass:"degress-col"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-10"},[_c('h5',[_vm._v(_vm._s(_vm.gradoNames[1]))])]),_c('div',{staticClass:"col-lg-2"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showAddLeadForm(_vm.gradoNames[1])}}},[_c('CIcon',{attrs:{"content":_vm.$options.plus}})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 degress-item",style:({
              'max-height':((_vm.windowHeight-300) + "px"),
              'height': (_vm.windowHeight + "px"),
              'overflow-y':'auto'
            })},[_c('table',{staticClass:"table"},[_c('tbody',_vm._l((_vm.twoDegreeColumn),function(item){return _c('tr',{key:item.id},[_c('td',[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showEditLeadForm(_vm.gradoNames[1], item)}}},[_c('p',{staticClass:"head mb-1"},[_vm._v(_vm._s(item.user_name))]),_c('p',{staticClass:"h6"},[_vm._v("Telefono : "+_vm._s(item.phone))]),_c('p',{staticClass:"h6"},[_vm._v("Email : "+_vm._s(item.email))])])])])}),0)])])])]):_vm._e()],1),_c('div',{staticClass:"col-lg-4"},[(_vm.threeDegreeColumnLoading)?_c('semipolar-spinner',{attrs:{"animation-duration":2000,"size":100,"color":"blue"}},[_vm._v("Spinner")]):_vm._e(),(!_vm.threeDegreeColumnLoading && _vm.threeDegreeColumn.length)?_c('div',{staticClass:"degress-col"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-10"},[_c('h5',[_vm._v(_vm._s(_vm.gradoNames[2]))])]),_c('div',{staticClass:"col-lg-2"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showAddLeadForm(_vm.gradoNames[2])}}},[_c('CIcon',{attrs:{"content":_vm.$options.plus}})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 degress-item",style:({
              'max-height':((_vm.windowHeight-300) + "px"),
              'height': (_vm.windowHeight + "px"),
              'overflow-y':'auto'
            })},[_c('table',{staticClass:"table"},[_c('tbody',_vm._l((_vm.threeDegreeColumn),function(item){return _c('tr',{key:item.id},[_c('td',[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showEditLeadForm(_vm.gradoNames[2], item)}}},[_c('p',{staticClass:"head mb-1"},[_vm._v(_vm._s(item.user_name))]),_c('p',{staticClass:"h6"},[_vm._v("Telefono : "+_vm._s(item.phone))]),_c('p',{staticClass:"h6"},[_vm._v("Email : "+_vm._s(item.email))])])])])}),0)])])])]):_vm._e()],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }